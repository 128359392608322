// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "./environment.model";

export const environment: IEnvironment = {
  production: false,
  authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906',
  clientId: 'aa00094f-b02c-443b-89be-24ac83dfb3f9',

  riskMetricsEndpoint: 'https://trading-preview-apis.azurewebsites.net/',
  tradeBlotterEndpoint: 'https://tradeblotterapis-ggot-dev.apps.dn.ocp.na.xom.com',

  riskMetricsAPIScope: 'api://aa00094f-b02c-443b-89be-24ac83dfb3f9/user_impersonation',
  tradeBlotterAPIScope: 'api://706d46c0-cc8d-45eb-bc1c-a121158b8224/user_impersonation',

  appInsightsConnectionString: '',

  version: 'Development'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 import 'zone.js/dist/zone-error';  // Included with Angular CLI.
